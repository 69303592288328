.usersInfoContainer {
    position: relative;
    width: 100%;
    min-height: 70px;
    border-radius: 10px;
    cursor: pointer;
    margin-bottom: 10px;
    background: linear-gradient(to top right, var(--alpha-light), var(--beta-light));
    display: flex;
    align-items: center;
    padding: 0.6rem;
    gap: 10px;
}

.usersInfoContainer:active{
    transform: scale(0.95);
    transition: all 0.3s ease-in-out;
}

.usersInfoContainer img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
    background-color: var(--gamma-light);
    padding: 0.3rem;
}

.indicator {
    width: 5px;
    height: 40px;
    background-color: var(--alpha-dark);
    border-radius: 10px;
    position: absolute;
    left: -2px;
}

.statusIndicator {
    position: absolute;
    top: -6px;
    background-color: var(--alpha-dark);
    color: var(--beta-light);
    font-size: 12px;
    width: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    left: 75%;
}

.userinfo {
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 5px;
}

.userinfo h5 {
    font-size: 18px;
    color: var(--alpha-dark);
    text-transform: capitalize;
}

.userinfo p {
    font-size: 15px;
    color: var(--alpha-dark);
}