.main{
    background: linear-gradient(to top right , var(--beta-light), var(--gamma-light));
    width: 100%;
    height: 100vh;
    position: relative;
    overflow-y: auto;
}

::-webkit-scrollbar {
    width: 6px;
}

::-webkit-scrollbar-track {
    background: var(--alpha-light);
}

::-webkit-scrollbar-thumb {
    background-image: linear-gradient(var(--alpha-light), var(--beta-light));
    border-radius: 12px;
}

::-webkit-scrollbar-thumb:hover {
    background: var(--alpha-light);
    border-radius: 12px;
}