.navbarContainer {
    width: 100%;
    height: 75px;
    position: fixed;
    z-index: 9999;
    background-color: var(--beta-light);
    box-shadow: 0px 0px 5px 0px var(--gamma-dark);
}

.wrapper {
    width: 90%;
    height: 100%;
    margin: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.userInfoContainer {
    /* width: 200px; */
    height: 100%;
    /* border: 2px solid red; */
    display: flex;
    align-items: center;
    gap: 15px;
    color: var(--alpha-dark);
    text-transform: capitalize;
    padding: .6rem;
    cursor: pointer;
}

.userInfoContainer img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
    background-color: var(--gamma-light);
    padding: 0.3rem;
}

.logo {
    height: 55px;
}

/* <--NavLink--> */
.navLinkWrapper {
    min-height: 150px;
    height: fit-content;
    width: 250px;
    position: absolute;
    left: 81.5%;
    /* background: linear-gradient(to top left, var(--beta-light), var(--alpha-light)); */
    background-color: var(--beta-light);
    border-radius: 0 0 16px 16px;
    display: none;
    padding: 0.5rem;
    box-shadow: 0px 5px 15px -7px var(--gamma-dark);
}

.toggleNavLinks {
    display: flex;
    animation: toggleNav 100ms 1 both;
}

@keyframes toggleNav {
    0% {
        top: 0;
        z-index: 1;
    }

    100% {
        z-index: 9999;
        top: 75px;
    }
}

.toggleNavLinks ul {
    display: flex;
    flex-direction: column;
    width: 100%;
    list-style: none;
}

.toggleNavLinks li {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.toggleNavLinks li:nth-last-child(1) {
    padding: 1rem;
}

.toggleNavLinks li img {
    width: 18px;
    margin-right: 8px;
}

.navLink {
    text-decoration: none;
    color: var(--alpha-dark);
    height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 1rem;
    border-radius: 8px;
}

.navLink:hover {
    background: var(--gamma-light);
    transition: all 0.4s ease-in-out;
}

.logoutBtn {
    width: 100%;
    height: 35px;
    border: none;
    border-radius: 10px;
    background: var(--alpha-dark);
    color: var(--gamma-light);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 15px;
}

.logoutBtn:active {
    transform: scale(.95);
}

@media (max-width: 1440px) {
    .navLinkWrapper {
        left: 78%
    }
}

@media (max-width: 1024px) {
    .navLinkWrapper {
        left: 71%
    }
}

@media (max-width: 786px) {
    .navLinkWrapper {
        left: 63%
    }
}

@media (max-width: 500px) {
    .userInfoContainer h2 {
        display: none;
    }

    .navLinkWrapper {
        left: 58%
    }

    .navLinkWrapper {
        width: fit-content;
    }
}