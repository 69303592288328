.wrapper{
    height: 100%;
    padding: 5px;
    display: flex;
    align-items: center;
    gap: 10px;
    /* border: 2px solid red; */
}

.wrapper img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
    background-color: var(--gamma-light);
    padding: 0.3rem;
}

.wrapper h3{
    text-transform: capitalize;
}

.deleteChat{
    height: 30px;
    width: 85px;
    border-radius: 10px;
    border: none;
    background-color: var(--alpha-dark);
    color: var(--delta-light);
    cursor: pointer;
}

.deleteChat:active{
    transform: scale(0.95);
}

.userContextBtn{
    height: 35px;
    width: 35px;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--delta-light);
}
.userContextBtn:active{
    transform: scale(0.95);
}