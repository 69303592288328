.userWrapper {
    width: 50%;
    max-width: 1440px;
    margin: 25px auto;
    padding: 1.2rem;
}

.headerWrapper {
    width: 100%;
    text-align: left;
    color: var(--alpha-dark);
    padding: 1.2rem;
}

.headerWrapper h2 {
    margin-bottom: 10px;
}

.headerWrapper p {
    margin-bottom: 10px;
    line-height: 25px;
}

.infoWrapper {
    border-radius: 16px;
    background: var(--gamma-light);
    box-shadow: -1px 1px 6px 0px var(--alpha-dark);
    height: 375px;
    transition: all 0.4s ease-in-out;
}

.flipInfoWrapper {
    transform: rotateY(180deg);
    transition: all 0.4s ease-in-out;
}

.deleteWrapper {
    height: 100%;
    transform: rotateY(180deg);
    color: var(--alpha-dark);
}

.deleteWrapper section {
    display: flex;
    align-items: flex-start !important;
    flex-direction: column !important;
}

.deleteWrapper article {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    gap: 8px;
    font-size: 16px;
    margin-bottom: 10px;
}

.deleteWrapper button {
    width: 150px;
    height: 40px;
    border-radius: 10px;
    border: none;
    font-size: 18px;
    cursor: pointer;
}

.deleteWrapper button:active {
    transform: scale(0.95);
}

.profileDeleteFooter {
    padding: 1.2rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 15px !important;
}

.profileDeleteFooter form{
    padding: 10px 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    font-size: 18px;
}
.profileDeleteFooter form input{
    height: 40px;
    border: none;
    border-radius: 10px;
    font-size: 18px;
    padding-left: 10px;
    background: var(--beta-light);
}
.profileDeleteFooter form input:focus{
    outline: 2px solid var(--alpha-dark);
}

.footerBtnWrapper{
    display: flex;
    gap: 10px;
}

.infoWrapper header {
    padding: 1.2rem;
    color: var(--alpha-dark);
    font-weight: 700;
    height: 50px;
    font-size: 18px;
    display: flex;
    align-items: center;
    border-radius: 16px 16px 0px 0px;
    background: var(--beta-light);
}

.infoWrapper section {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 1.2rem;
}

.profileImageWrapper {
    margin-right: 0.3rem;
    position: relative;
}

.userInfo {
    width: 100%;
    height: 100%;
    margin-left: 0.3rem;
}

.profileImage {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    background: var(--beta-light);
    padding: 0.6rem;
}

.imageEditBtn {
    position: absolute;
    top: 70%;
    left: 80%;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: none;
    cursor: pointer;
}

.imageEditBtn:active {
    transform: scale(0.95);
}

.imageEditBtn img {
    width: 30px;
}

.data {
    border-bottom: 1px solid var(--alpha-dark);
    display: flex;
    min-height: 45px;
    height: fit-content;
    align-items: center;
    gap: 20px;
    padding: 0.6rem;
    margin: 5px;
}

.data p {
    font-size: 20px;
    font-weight: 500;
    width: 50px;
}

.data h4 {
    font-size: 20px;
    text-transform: capitalize;
}

.profileFooter {
    padding: 1.2rem;
    height: 45px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 15px;
}

.editBtn,
.deleteBtn,
.cancelBtn,
.saveBtn {
    border: none;
    width: 150px;
    height: 30px;
    border-radius: 10px;
    cursor: pointer;
}

.editBtn,
.cancelBtn,
.saveBtn {
    background: var(--beta-light);
}

.deleteBtn {
    background: var(--danger);
}

.editBtn:active {
    transform: scale(0.95);
}

.deleteBtn:active {
    transform: scale(0.95);
}

.cancelBtn:active {
    transform: scale(0.95);
}

.saveBtn:active {
    transform: scale(0.95);
}

@media (max-width: 1260px) {
    .userWrapper {
        width: 70%;
    }
}

@media (max-width: 1024px) {
    .userWrapper {
        width: 85%;
    }
}

@media (max-width: 768px) {
    .userWrapper {
        width: 90%;
    }

    .infoWrapper section {
        flex-wrap: wrap;
    }
}

@media (max-width: 500px) {
    .userWrapper {
        width: 100%;
    }

    .infoWrapper section {
        flex-wrap: wrap;
    }
}