.wrapper {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.wrapper img {
    width: 110px;
    margin-top: 50px;
}

.form {
    width: 500px;
    box-shadow: -3px 2px 8px 0px var(--beta-dark);
    border-radius: 18px;
    padding: 1.2rem;
    margin-top: 20px;
}

.formControl {
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;
    padding: 0.6rem;
    position: relative;
}

.formControl label {
    font-size: 18px;
    margin-bottom: 8px;
    color: var(--alpha-dark);
    padding-left: 4px;
}

.formControl label span {
    color: red;
}

.formControl input {
    background-color: var(--beta-light);
    border: none;
    height: 45px;
    color: var(--alpha-dark);
    border-radius: 18px;
    padding-left: 12px;
    outline: none;
    font-size: 17px;
}

.formControl input::placeholder {
    color: var(--alpha-dark);
}

.formControl input:focus {
    outline: 2px solid var(--beta-dark);
}

.submitBtn {
    background-color: var(--beta-dark);
    height: 45px;
    border: none;
    border-radius: 16px;
    color: var(--gamma-light);
    font-size: 16px;
    cursor: pointer;
}

.submitBtn:active {
    transform: scale(0.9);
    transition: all 0.3s ease-in-out;
}

.formHeader {
    color: var(--alpha-dark);
    text-align: center;
}

.formFooter {
    display: flex;
    justify-content: flex-start;
    height: 30px;
    align-items: center;
    gap: 20px;
    padding: 1.2rem;
    color: var(--alpha-dark);
    font-size: 17px;
}

.footerLinks {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
    height: 25px;
    padding: 1.2rem;
}

.loginLink {
    color: var(--alpha-dark);
}

.hideShow {
    position: absolute;
    top: 50px;
    left: 85%;
    width: 25px;
    height: 25px;
    cursor: pointer;
}

.hideShow:active {
    transform: scale(0.9);
}

.checkPersist {
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    padding: 1.0rem;
}

.checkPersist input {
    height: 20px;
    width: 20px;
}

@media (max-width: 550px) {
    .form {
        width: 90% !important;
    }

    .verifyWrapper {
        width: 90% !important;
    }
}

.verifyWrapper {
    width: 500px;
    box-shadow: -3px 2px 8px 0px var(--beta-dark);
    border-radius: 10px;
    margin-top: 20px;
    padding: 1.2rem;
    font-size: 18px;
    line-height: 30px;
}

.verifyWrapper h3 {
    text-align: center;
    margin-bottom: 20px;
}

.verifyWrapper span {
    font-weight: 700;
}

.verifyBtn {
    width: 200px;
    height: 45px;
    margin-top: 20px;
    border: none;
    border-radius: 18px;
    background: var(--alpha-dark);
    color: var(--gamma-light);
    font-size: 18px;
    cursor: pointer;
}

.verifyBtn:active {
    transform: scale(0.95);
}